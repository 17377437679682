import { useRouter } from 'next/router';
import { slugifyArray } from '../../helper/vehicle_url';
import { generateQueryParams } from '../../helper/functions';

import IOption from "../../interfaces/IOption";
import iFilters from '../../interfaces/iFilters';

import { event } from '../../lib/gtag';

/**
 * @description: generates function that performs search based on:
 * @param makes: IOption[] (array of makes)
 * @param models: IOption[] (array of models)
 * @param vehicleType vehicle type (private, commercial, finance, leasing, used)
 * @param eventTag event tag for analytics
 * @param filters (filters could be literally any IOption array or field that can be parsed into URL)
 * @param baseURL (base URL for search)
 * @param addAll (adds all to the end of URL if needed)
 * @returns vehicles search function 
 */

export default function SearchTool(
  makes: IOption[],
  models: IOption[],
  baseURL: string,
  eventTag: string,
  filters?: iFilters,
  addAll?: boolean,
  filtersURL?: string,
  resultsItem?: string,
) {
  const router = useRouter();

  const searchFunc = () => {
    let url = `${baseURL}`

    if (addAll) url += '/all'

    event(eventTag)

    // Adding makes and models to URL (works like [slug] not queryParam)
    if (makes.length) {
      url = `${baseURL}/`

      if (models.length) {
        url += `${slugifyArray(models)}@`
      }

      url += slugifyArray(makes)
    }

    if (filters) {
      url += generateQueryParams(filters);

      sessionStorage.setItem(filtersURL || "filtersURL", url)
      router.replace(url, undefined, { shallow: true });
    }
    else {
      sessionStorage.setItem(filtersURL || "filtersURL", url)
      sessionStorage.removeItem(resultsItem || "vehiclesResults")
      
      router.push(url)
    } 
  }

  return searchFunc
};
